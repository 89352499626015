import { Consumption } from "../../../components/consumption/Consumption";
import { ScaleUp, SmoothUp } from "components/animations/SnapUp";
import userStore, { getAssetOwnerId, getUserRole } from "store/user.store";
import { useAssetOwnerWithAssetId } from "api/assetOwner/assetOwner.endpoints";
import { BreadCrumb, menuStore } from "store/menu.store";
import { RouteUrl } from "routing/routeUrl.enum";
import { RouteLabel } from "routing/routeLabel.enum";
import { PanelRow } from "components/PanelRow";
import { useEffect } from "react";
import { SystemNotificationInfo } from "components/systemNotification/SystemNotificationInfo";
import { useActiveZavannSystemNotifications } from "api/system-notification/systemNotification.endpoints";
import { Panel } from "../../../components/Panel";
import { ElectricityAgreement } from "../../../components/electricityAgreement/ElectricityAgreement";
import { useTranslation } from "react-i18next";
import { DashboardGreetingHeader } from "components/shared/DashboardGreetingHeader";
import { Battery } from "../../../components/battery/Battery";

export const AssetOwnerDashboardPage = () => {
  const { t } = useTranslation();

  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs([getAssetOwnerDashboardPageBreadcrumb()]);
  }, []);

  const userRole = userStore(getUserRole);

  const assetOwnerId = userStore(getAssetOwnerId) || -1;

  // Get Asset owner
  const { data: assetOwner, isLoading: isLoadingAssetOwner } =
    useAssetOwnerWithAssetId(+assetOwnerId);

  // Get active zavann system notifications
  const { data: systemNotification } = useActiveZavannSystemNotifications();

  return (
    <SmoothUp>
      <DashboardGreetingHeader name={assetOwner?.firstName ?? ""} />
      <PanelRow>
        <Panel
          header={t(`batteryOverview.Title`)}
          isLoading={isLoadingAssetOwner}
        >
          <Battery assetId={assetOwner?.assetId} userRole={userRole} />
        </Panel>

        <Panel
          header={t(`electricityAgreement.Title`)}
          isLoading={isLoadingAssetOwner}
        >
          {systemNotification ? (
            <SystemNotificationInfo notification={systemNotification} />
          ) : (
            <ElectricityAgreement assetOwnerId={+assetOwnerId} />
          )}
        </Panel>
      </PanelRow>
      <Panel
        header={t(`consumptionAndRevenue.title`)}
        isLoading={isLoadingAssetOwner}
      >
        <ScaleUp>
          <Consumption
            assetOwnerId={+assetOwnerId}
            hasSystemNotification={!!systemNotification}
            hasShadow={false}
          />
        </ScaleUp>
      </Panel>
    </SmoothUp>
  );
};

export const getAssetOwnerDashboardPageBreadcrumb = (): BreadCrumb => ({
  label: RouteLabel.Dashboard,
  to: `${RouteUrl.App}/${RouteUrl.Dashboard}`,
});
