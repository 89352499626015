import { FC } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  ConsumptionChartItem,
  getChartDomainMoney,
  getChartDomainPower,
} from "../../helpers/assetOwnerDashboard";

interface Props {
  data?: ConsumptionChartItem[];
}

export const DesktopVersion: FC<Props> = ({ data = [] }) => {
  return (
    <div className="bg-white p-2 rounded">
      <ResponsiveContainer height={400}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            bottom: 20,
            left: 10,
            right: 10,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis
            yAxisId="left"
            label={<CustomYAxisLabel value={"SEK"} xIncrease={5} />}
            axisLine={false}
            domain={getChartDomainMoney(data)}
            tickCount={5}
            tickLine={false}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={<CustomYAxisLabel value={"kWh"} xIncrease={60} />}
            axisLine={false}
            domain={getChartDomainPower(data)}
            tickCount={5}
            tickLine={false}
          />
          <Tooltip />
          <Legend
            fontStyle={"0.5rem"}
            formatter={(value) => (
              <span className="font-normal text-gray-600">{value}</span>
            )}
          />
          <Bar
            name="Household consumption (kWh)"
            yAxisId="right"
            dataKey="kWh"
            barSize={20}
            // This is not part of the FlowerHub color scheme in tailwind.config
            // flowerblue-500 is too light and flowerblue-600 too dark
            // So we will use this until we have a suitable one in the color scheme
            fill="#78A7D3"
          />
          <Line
            name="Monthly total (SEK)"
            yAxisId="left"
            type="linear"
            dataKey="SEK"
            stroke="#f472b6"
            dot={false}
            strokeWidth={2}
            strokeDasharray="5 5"
          />
          <Line
            name="Flower Hub revenue (SEK)"
            yAxisId="left"
            type="linear"
            dataKey="flower"
            stroke="#fb923c"
            dot={false}
            strokeWidth={2}
            strokeDasharray="5 5"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

type ViewBox = { x: number; y: number; height: number };

interface CustomYAxisLabelProps {
  viewBox?: ViewBox;
  value?: string;
  xIncrease?: number;
  isMobile?: boolean;
}

export const CustomYAxisLabel: FC<CustomYAxisLabelProps> = ({
  viewBox = { x: 0, y: 0, height: 0 },
  value,
  xIncrease = 0,
  isMobile = false,
}) => {
  const { x, y, height } = viewBox;

  return (
    <text
      x={x + xIncrease}
      y={y + height / 2}
      fill="#616E7C"
      fontSize={isMobile ? "0.8em" : "default"}
      textAnchor="middle"
      transform={`rotate(-90, ${x + xIncrease}, ${y + height / 2})`}
    >
      {value}
    </text>
  );
};
