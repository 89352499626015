import {
  OperationalStatusAnimationTypes,
  OperationStatusGridProps,
} from "components/flowerHub/operationalProgress/OperationStatusGrid";
import { BadgeColor } from "components/tags/Badge";
import {
  FlowerHubOperationalStatusMessageCode,
  FlowerHubStatus,
} from "enums/flowerHubStatus.enum";

export function getOperationalStatus(
  message: FlowerHubOperationalStatusMessageCode,
): {
  status: FlowerHubStatus;
  color: BadgeColor;
} {
  switch (message) {
    case FlowerHubOperationalStatusMessageCode.GreengrassDeploymentInProgress:
      return { status: FlowerHubStatus.Updating, color: "blue" };
    case FlowerHubOperationalStatusMessageCode.GreengrassDeploymentFailed:
    case FlowerHubOperationalStatusMessageCode.GreengrassDeploymentUnknownStatus:
      return { status: FlowerHubStatus.UpdateFailed, color: "red" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleScanningAndComponentsAreRunning:
      return { status: FlowerHubStatus.Connecting, color: "blue" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleScanningAndComponentsAreUnhealthy:
      return { status: FlowerHubStatus.Connecting, color: "blue" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleScanningAndComponentsAreBroken:
      return { status: FlowerHubStatus.DeviceError, color: "red" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleFoundAndComponentsAreRunning:
      return { status: FlowerHubStatus.Connected, color: "green" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleFoundAndComponentsAreUnhealthy:
      return { status: FlowerHubStatus.Unhealthy, color: "yellow" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleFoundAndComponentsAreBroken:
      return { status: FlowerHubStatus.DeviceError, color: "red" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleNotFoundAndComponentsAreRunning:
      return { status: FlowerHubStatus.ConnectionFailed, color: "red" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleNotFoundAndComponentsAreUnhealthy:
      return { status: FlowerHubStatus.ConnectionFailed, color: "red" };
    case FlowerHubOperationalStatusMessageCode.InverterDongleNotFoundAndComponentsAreBroken:
      return { status: FlowerHubStatus.DeviceError, color: "red" };
    default:
      return { status: FlowerHubStatus.Unknown, color: "gray" };
  }
}

export const operationStatusMapping: Record<
  FlowerHubOperationalStatusMessageCode,
  OperationStatusGridProps
> = {
  [FlowerHubOperationalStatusMessageCode.GreengrassDeploymentInProgress]: {
    leftAnimationType: OperationalStatusAnimationTypes.Unknown,
    rightAnimationType: OperationalStatusAnimationTypes.ConnectingAnimation,
    leftText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentInProgress.leftText",
    rightText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentInProgress.rightText",
    rightActionText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentInProgress.rightActionText",
  },
  [FlowerHubOperationalStatusMessageCode.GreengrassDeploymentFailed]: {
    leftAnimationType: OperationalStatusAnimationTypes.Unknown,
    rightAnimationType: OperationalStatusAnimationTypes.ErrorAnimation,
    leftText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentFailed.leftText",
    rightText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentFailed.rightText",
    rightActionText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentFailed.rightActionText",
  },
  [FlowerHubOperationalStatusMessageCode.GreengrassDeploymentUnknownStatus]: {
    leftAnimationType: OperationalStatusAnimationTypes.Unknown,
    rightAnimationType: OperationalStatusAnimationTypes.ErrorAnimation,
    leftText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentUnknownStatus.leftText",
    rightText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentUnknownStatus.rightText",
    rightActionText:
      "flowerHubProgress.operationalStatus.GreengrassDeploymentUnknownStatus.rightActionText",
  },
  [FlowerHubOperationalStatusMessageCode.InverterDongleScanningAndComponentsAreRunning]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.ConnectingAnimation,
      rightAnimationType: OperationalStatusAnimationTypes.ConnectedAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreRunning.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreRunning.rightText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleScanningAndComponentsAreUnhealthy]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.ConnectingAnimation,
      rightAnimationType: OperationalStatusAnimationTypes.UnhealthyAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreUnhealthy.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreUnhealthy.rightText",
      rightActionText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreUnhealthy.rightActionText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleScanningAndComponentsAreBroken]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.Unknown,
      rightAnimationType: OperationalStatusAnimationTypes.ErrorAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreBroken.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreBroken.rightText",
      rightActionText:
        "flowerHubProgress.operationalStatus.InverterDongleScanningAndComponentsAreBroken.rightActionText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleFoundAndComponentsAreRunning]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.ConnectedAnimation,
      rightAnimationType: OperationalStatusAnimationTypes.ConnectedAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreRunning.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreRunning.rightText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleFoundAndComponentsAreUnhealthy]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.ConnectedAnimation,
      rightAnimationType: OperationalStatusAnimationTypes.UnhealthyAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreUnhealthy.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreUnhealthy.rightText",
      rightActionText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreUnhealthy.rightActionText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleFoundAndComponentsAreBroken]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.Unknown,
      rightAnimationType: OperationalStatusAnimationTypes.ErrorAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreBroken.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreBroken.rightText",
      rightActionText:
        "flowerHubProgress.operationalStatus.InverterDongleFoundAndComponentsAreBroken.rightActionText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleNotFoundAndComponentsAreRunning]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.ErrorAnimation,
      rightAnimationType: OperationalStatusAnimationTypes.ConnectedAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreRunning.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreRunning.rightText",
      leftActionText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreRunning.leftActionText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleNotFoundAndComponentsAreUnhealthy]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.ErrorAnimation,
      rightAnimationType: OperationalStatusAnimationTypes.UnhealthyAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreUnhealthy.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreUnhealthy.rightText",
      leftActionText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreUnhealthy.leftActionText",
      rightActionText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreUnhealthy.rightActionText",
    },
  [FlowerHubOperationalStatusMessageCode.InverterDongleNotFoundAndComponentsAreBroken]:
    {
      leftAnimationType: OperationalStatusAnimationTypes.Unknown,
      rightAnimationType: OperationalStatusAnimationTypes.ErrorAnimation,
      leftText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreBroken.leftText",
      rightText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreBroken.rightText",
      leftActionText:
        "flowerHubProgress.operationalStatus.InverterDongleNotFoundAndComponentsAreBroken.rightActionText",
    },
};
