import { FC } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  ConsumptionChartItem,
  getChartDomainMoney,
  getChartDomainPower,
} from "../../helpers/assetOwnerDashboard";
import { CustomYAxisLabel } from "./DesktopVersion";

interface Props {
  data?: ConsumptionChartItem[];
}

export const MobileVersion: FC<Props> = ({ data = [] }) => {
  return (
    <div className="bg-white py-4 px-2 rounded-md">
      <ResponsiveContainer height={400} width="100%">
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            bottom: 5,
            left: 0,
            right: 0,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis
            yAxisId="left"
            label={
              <CustomYAxisLabel value={"SEK"} xIncrease={15} isMobile={true} />
            }
            axisLine={false}
            domain={getChartDomainMoney(data)}
            tickCount={5}
            tickLine={false}
            fontSize="12"
            style={{
              fontSize: "0.7rem",
              fontFamily: "Arial",
            }}
          ></YAxis>
          <YAxis
            yAxisId="right"
            orientation="right"
            label={
              <CustomYAxisLabel value={"kWh"} xIncrease={50} isMobile={true} />
            }
            axisLine={false}
            domain={getChartDomainPower(data)}
            tickCount={5}
            tickLine={false}
            style={{
              fontSize: "0.7rem",
              fontFamily: "Arial",
            }}
          />
          <Tooltip />
          <Legend
            fontStyle={"0.5rem"}
            formatter={(value) => (
              <span className="font-normal text-gray-600"> {value} </span>
            )}
          />
          <Bar
            name="Household consumption (kWh)"
            yAxisId="right"
            dataKey="kWh"
            barSize={10}
            // This is not part of the FlowerHub color scheme in tailwind.config
            // flowerblue-500 is too light and flowerblue-600 too dark
            // So we will use this until we have a suitable one in the color scheme
            fill="#78A7D3"
          />
          <Line
            name="Monthly total (SEK)"
            yAxisId="left"
            type="linear"
            dataKey="SEK"
            stroke="#f472b6"
            dot={false}
            strokeWidth={1}
            strokeDasharray="5 5"
          />
          <Line
            name="Flower Hub revenue (SEK)"
            yAxisId="left"
            type="linear"
            dataKey="flower"
            stroke="#fb923c"
            dot={false}
            strokeWidth={1}
            strokeDasharray="5 5"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
