import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../helpers/date";
import { SiteForElectricityAgreementDTO } from "../../api/assetOwner/assetOwner.dto";
import { Item, Label, List } from "components/descriptionList";
import { ElectricityAgreementTag } from "../tags/ElectricityAgreementTag";
import CustomPlaceholder from "components/CustomPlaceholder";
import { useAssetOwnerElectricityAgreement } from "api/assetOwner/assetOwner.endpoints";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { LatestInvoices } from "../invoices/LatestInvoices";

interface Props {
  assetOwnerId: number;
}

export const ElectricityAgreement: FC<Props> = ({ assetOwnerId }) => {
  const { t } = useTranslation();

  // Get electricity agreement
  const {
    data: electricityAgreement,
    isLoading: isLoadingElectricityAgreement,
    isError: isErrorElectricityAgreement,
  } = useAssetOwnerElectricityAgreement(assetOwnerId);

  const renderElectricityAgreementPlaceholder = () => (
    <div className="sm:divide-y sm:divide-gray-50 bg-white rounded-md my-8">
      <CustomPlaceholder
        isError={isErrorElectricityAgreement}
        isLoading={isLoadingElectricityAgreement}
        isEmpty={
          !isLoadingElectricityAgreement &&
          !isErrorElectricityAgreement &&
          !electricityAgreement
        }
        icon={<DocumentTextIcon />}
        errorMessage={t(
          `electricityAgreement.UnableToFetchElectricityAgreement`,
        )}
        emptyDataMessage={t(`electricityAgreement.NoElectricityAgreement`)}
        loaderSize="medium"
      />
    </div>
  );

  if (
    isLoadingElectricityAgreement ||
    !electricityAgreement ||
    isErrorElectricityAgreement
  ) {
    return renderElectricityAgreementPlaceholder();
  }

  return (
    <div className="sm:divide-y sm:divide-gray-50 bg-white rounded-md">
      <List>
        {electricityAgreement.consumption && (
          <AgreementItem
            label={t(`electricityAgreement.Consumption`)}
            agreement={electricityAgreement.consumption}
          />
        )}
        {electricityAgreement.production && (
          <AgreementItem
            label={t(`electricityAgreement.Production`)}
            agreement={electricityAgreement.production}
          />
        )}
      </List>
      <LatestInvoices assetOwnerId={assetOwnerId} />
    </div>
  );
};

interface AgreementItemProp {
  label: string;
  agreement: SiteForElectricityAgreementDTO;
}

const AgreementItem = ({ label, agreement }: AgreementItemProp) => {
  return (
    <Item className="relative">
      <div className="mt-1 text-base sm:mt-0 text-gray-900 flex justify-between items-center">
        <Label>{label}</Label>
      </div>
      <div className="flex justify-start align-middle items-center text-sm">
        <ElectricityAgreementTag
          stateCategory={agreement.stateCategory}
          stateId={agreement.stateId}
          stateReasonId={agreement.stateReasonId}
        />
      </div>
      <div className="mt-1 text-base text-gray-900 sm:mt-0">
        {agreement.startDate && (
          <DateItem
            label="electricityAgreement.Start"
            date={agreement.startDate}
          />
        )}
        {agreement.terminationDate && (
          <DateItem
            label="electricityAgreement.End"
            date={agreement.terminationDate}
          />
        )}
      </div>
    </Item>
  );
};

interface DateItemProp {
  label: string;
  date: Date;
}

const DateItem = ({ label, date }: DateItemProp) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between md:justify-end align-middle items-center text-sm">
      <span className="font-medium text-gray-500">{`${t(label)}:`}</span>
      <span className="ml-4">{formatDate(date)}</span>
    </div>
  );
};
