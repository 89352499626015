import React, { RefObject, useRef, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { isMobile } from "react-device-detect";

export enum OperationalStatusAnimationTypes {
  ConnectingAnimation = "connecting",
  ConnectedAnimation = "connected",
  ErrorAnimation = "error",
  UnhealthyAnimation = "unhealthy",
  Unknown = "unknown",
}

export interface LottiePlayerProps {
  animationType: OperationalStatusAnimationTypes;
  id: string;
}

const LottiePlayer: React.FC<LottiePlayerProps> = ({ animationType, id }) => {
  const playerRef: RefObject<Player> = useRef<Player>(null);
  const [animationIndex, setAnimationIndex] = useState(1);

  const moveToNextAnimation = () => {
    const player = playerRef.current;
    if (player) {
      if (
        animationType === OperationalStatusAnimationTypes.ConnectedAnimation ||
        animationType === OperationalStatusAnimationTypes.UnhealthyAnimation
      ) {
        setAnimationIndex(animationIndex + 1);
      }
    }
  };

  const isLoopingAnimation =
    animationType === OperationalStatusAnimationTypes.ConnectingAnimation ||
    (animationType === OperationalStatusAnimationTypes.ConnectedAnimation &&
      animationIndex === 2) ||
    (animationType === OperationalStatusAnimationTypes.UnhealthyAnimation &&
      animationIndex === 2);

  const animationSrc = `/operational-status/${isMobile ? "mobile/" : ""}${animationType}${
    animationType === OperationalStatusAnimationTypes.ConnectedAnimation ||
    animationType === OperationalStatusAnimationTypes.UnhealthyAnimation
      ? `_${animationIndex}`
      : ""
  }.json`;

  return (
    <Player
      id={id}
      autoplay={
        animationType === OperationalStatusAnimationTypes.Unknown ? false : true
      }
      loop={isLoopingAnimation}
      keepLastFrame={true}
      ref={playerRef}
      src={animationSrc}
      style={{
        width: isMobile ? "40px" : "160px",
        height: isMobile ? "100px" : "40px",
      }}
      onEvent={(event) => {
        if (event === "complete") {
          moveToNextAnimation();
        }
      }}
    ></Player>
  );
};

export default LottiePlayer;
